#slider_trailer {
  z-index: 50;
}
#slider_streaming {
  z-index: 50;
}
#slider_movie .mobile-only {
  display: initial;
}
#slider_movie .movie_cont {
  display: none;
}
#slider_movie {
  z-index: 50;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#slider_movie .movie-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0));
  min-height: 50px;
}
#slider_movie .movie-menu__close-arrow {
  display: inline-block;
  cursor: pointer;
  float: left;
  margin: 10px 0 0 10px;
  width: 24px;
  height: 24px;
  position: relative;
  text-shadow: 0 0 5px #000;
  vertical-align: middle;
  background: url(../fonts/icons-arrow_back-24px.svg) no-repeat center center transparent;
  background-size: cover;
}
#slider_movie .movie-menu__add-favorite.js-add-favorite {
  display: inline-block;
  cursor: pointer;
  float: right;
  margin: 10px 20px 0 0;
  width: 24px;
  height: 24px;
  position: relative;
  text-shadow: 0 0 5px #000;
  vertical-align: middle;
  background: url(../fonts/icons-favorite_border-24px.svg) no-repeat center center transparent;
  background-size: cover;
}
#slider_movie .movie-menu__add-favorite.js-add-favorite.infavs {
  background: url(../fonts/icons-favorite-24px.svg) no-repeat center center transparent;
}
#slider_movie .info-container {
}
#slider_movie .info-container__poster {
  width: 100%;
  height: 80vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
#slider_movie .movie-info__rating {
  margin: 0 10px;
  /*text-shadow: 0 0 5px #228DFF, 0 0 10px #228DFF, 0 0 15px #228DFF, 0 0 20px #fff, 0 0 35px #fff, 0 0 40px #228DFF, 0 0 50px #228DFF, 0 0 75px #228DFF;*/
  color: #fff;
}
#slider_movie .info-container__content {
  position: relative;
  top: 80vh;
  margin-top: -125px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 60%, rgba(255, 255, 255, 0));
  min-height: 50px;
}
#slider_movie .movie-info__title {
  font-size: 34px;
  /* text-align: center; */
  box-sizing: border-box;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
  font-family: opensansbold;
  text-transform: uppercase;
}
#slider_movie .movie-info__details {
  margin: 0 10px;
}
#slider_movie .movie-info__genres,
#slider_movie .movie-info__year,
#slider_movie .movie-info__runtime {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  margin-right: 10px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
}
#slider_movie .movie-synopsis {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  margin: 0 10px;
}
#slider_movie .synopsis-buttons {
  padding: 10px;
  font-size: 14px;
  line-height: initial;
  margin-top: -60px;
  margin-bottom: 30px;
}
#slider_movie .synopsis-buttons__review {
  font-size: 14px;
  line-height: initial;
  padding: 5px 10px;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  color: #444;
  background: #fff;
  border-radius: 3px;
  transition: all 1s;
  border: 1px solid #fff;
}
#slider_movie .synopsis-buttons__trailer {
  vertical-align: middle;
  position: relative;
  color: #fff;
  display: inline-block;
  border: 1px rgba(255, 255, 255, 0.1) solid;
  border-radius: 3px;
  margin: 0 0 0 5px;
  padding: 5px 10px 5px 35px;
  cursor: pointer;
  background: url(../fonts/icons-videocam-24px.svg) no-repeat 5px center rgba(255, 255, 255, 0.3);
  background-size: 24px;
  transition: all 1s;
}
#slider_movie .synopsis-buttons__trailer {
  vertical-align: middle;
  position: relative;
  color: #fff;
  display: inline-block;
  border: 1px rgba(255, 255, 255, 0.1) solid;
  border-radius: 3px;
  margin: 0 0 0 5px;
  padding: 5px 10px 5px 35px;
  cursor: pointer;
  background: url(../fonts/icons-videocam-24px.svg) no-repeat 5px center rgba(255, 255, 255, 0.3);
  background-size: 24px;
  transition: all 1s;
}
.movie-controls {
  list-style: none;
  margin: 10px 0 30px 0;
  padding: 0;
}
.movie-controls:after {
  content: "";
  position: relative;
  display: block;
  clear: both;
}
.movie-controls li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  display: block;
}
.movie-controls li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
#slider_movie .mobile-dubbing {
  font-size: 16px;
  background: black;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
}
#slider_movie .mobile-subtitles {
  font-size: 16px;
  background: black;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  border: none;
  padding: 5px 10px;
  margin: 10px 0 10px 0;
}
#slider_movie .mobile-torrents {
  font-size: 16px;
  background: black;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  border: none;
  padding: 5px 10px;
  margin: 10px 0 10px 0;
}
@media screen and (min-width: 568px) {
}
@media screen and (min-width: 1200px) {
  #slider_movie .mobile-only {
    display: none;
  }
  #slider_movie .movie_cont {
    display: initial;
  }
  #slider_movie .close {
    top: 0px;
  }
}
@media screen and (max-width: 1199px) {
  /**
    hide desktop controls;
     */
  #slider_movie .synopsis .reviews-btn {
    display: none;
  }
  #slider_movie #watch_toolbox {
    display: none;
  }
  #slider_movie .close {
    display: none;
  }
  #slider_movie .backdrop {
    display: none;
  }
  #slider_movie .title_info {
    float: none;
    display: inline-block;
    font-size: 18px;
  }
  #slider_movie .title_info.stars {
    display: block;
    text-align: center;
    margin-top: 15px;
  }
  #slider_movie .title_info_cont {
    padding: 10px 10px 10px;
    text-align: center;
  }
  #slider_movie .title_info_cont:after {
    content: "";
    display: block;
    clear: both;
  }
  #slider_movie .controls {
    display: block;
  }
  #slider_movie .controls:after {
    content: "";
    display: block;
    clear: both;
  }
  div.controls > div > svg > path {
    cursor: pointer !important;
  }
  #slider_movie .controls__play {
    float: right;
    margin-right: 45px;
    /*text-shadow: 0 0 5px #228DFF, 0 0 10px #228DFF, 0 0 15px #228DFF, 0 0 20px #fff, 0 0 35px #fff, 0 0 40px #228DFF, 0 0 50px #228DFF, 0 0 75px #228DFF;*/
  }
  #slider_movie .controls__play .play-button {
    width: 70px;
    height: 70px;
    cursor: pointer;
    transition: all 1s;
  }
  #slider_movie .mobile-only {
    display: block;
  }
  #slider_movie .incont {
    height: initial;
  }
  #slider_movie .movie_cont {
    height: 100%;
  }
  #slider_movie .movie_cont .poster_img {
    width: 100%;
    height: auto;
    min-height: 780px;
    position: relative;
    top: 0;
  }
  #slider_movie .movie_cont .content {
    margin-top: -220px;
    width: 100%;
    min-width: initial;
    position: relative;
    top: initial;
  }
  #slider_movie .bottom_toolbox {
    position: relative;
  }
  #slider_movie .toolbox_content {
    position: relative;
    bottom: auto;
    min-width: initial;
    height: auto;
    top: auto;
    width: 100%;
  }
  #slider_movie .toolbox_content:after {
    content: "";
    position: relative;
    display: block;
    clear: both;
  }
}
